import Lock from "./common/Lock/Lock";
import Cookie from "js-cookie";

import "./App.styl";
import Button from "./common/Button/Button";

class App extends React.Component {
    state = {
        unlocked: false,
        password: null,
        loggedIn: false,
        loaded: false
    }

    async componentDidMount() {
        let password = Cookie.get("password");
        if(password) {
            const result = await axios.get("/api/check/" + password);
            if(!result.data?.valid) {
                password = "";
                Cookie.set("password", password);
            }
        }

        if(password) {
            Cookie.set("password", password, { expires: 7 });
            this.setState({ password, loggedIn: !!password });
        }
        this.setState({ loaded: true });
    }

    login = async e => {
        e.preventDefault();

        const result = await axios.get("/api/check/" + this.state.password);
        if(result.data?.valid) {
            Cookie.set("password", this.state.password, { expires: 30 });
            this.setState({ loggedIn: true });
        }
    }

    open = () => {
        axios.get("/api/open/" + this.state.password);
    }

    unlock = () => {
        this.setState({ unlocked: true });
        setTimeout(() => {
            this.setState({ unlocked: false });
        }, 30000);
    }

    render() {
        return this.state.loaded && (
            this.state.loggedIn ? (
                <div className="Center">
                    {this.state.unlocked ? (
                        <Button onClick={this.open}>Kapunyitás</Button>
                    ) : (
                        <Lock 
                            pins={4}
                            positions={[
                                50,
                                20,
                                30,
                                80
                            ]}
                            onUnlocked={this.unlock}
                        />
                    )}
                </div>
            ) : (
                <div className="Form">
                    <p>Jelszó: </p>
                    <form onSubmit={this.login}>
                        <input type="password" value={this.state.password || ""} onChange={e => this.setState({ password: e.target.value })} />
                        <Button>Bejelentkezés</Button>
                    </form>
                </div>
            )
        );
    }
}

export default App;