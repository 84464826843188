import "./KeyPin.styl";

class KeyPin extends React.Component {
    state = {
        position: 0
    }

    startPosition = 0
    rightPosition = 0
    min = 0
    max = 100

    containerRef = React.createRef();

    componentDidMount() {
        this.rightPosition = (this.containerRef.current.clientWidth * this.props.position / 100) - 16; 
        const tolerance = this.containerRef.current.clientWidth * (this.props.tolerance || 5) / 100

        this.min = this.rightPosition - tolerance;
        this.max = this.rightPosition + tolerance;
        this.forceUpdate();
    }

    touchStart = (e) => {
        this.startPosition = e.targetTouches[0].clientX;
    }

    touchMove = (e) => {
        const position = e.targetTouches[0].clientX - this.startPosition;
        if(position < 0)
            return;
        if(position > this.containerRef.current.clientWidth - 32)
            return;
            
        this.setState({ position })
    }

    touchEnd = (e) => {
        this.props.onChange?.(this.min < this.state.position && this.state.position < this.max);
        this.setState({
            position: 0
        });
    }

    render() {
        return (
            <div 
                ref={this.containerRef}
                className={classnames("KeyPin", {
                    "KeyPin--unlocked": this.props.unlocked,
                    "KeyPin--position": this.min < this.state.position && this.state.position < this.max
                })}
                onTouchStart={this.touchStart}
                onTouchMove={this.touchMove}
                onTouchEnd={this.touchEnd}
            >
                <div 
                    className="KeyPin__knob"
                    style={{
                        left: `${this.state.position || 0}px`
                    }}
                />
                <div className="KeyPin__position-marker-wrapper">
                    <div
                        className="KeyPin__position-marker"
                        style={{
                            width: `${this.max - this.min + 48}px`,
                            marginLeft: `${this.min || 0}px`
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default KeyPin;

