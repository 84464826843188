import React, { Component } from "react";
import KeyPin from "../KeyPin/KeyPin";

import "./Lock.styl";

class Lock extends Component {
    state = {
        pins: [...new Array(this.props.pins)].map(x => false)
    }

    currentPin = 0

    pinChange = (value, index) => {
        if(index !== this.currentPin || !value) {
            this.setState({
                pins: [...new Array(this.props.pins)].map(x => false)
            });
            this.currentPin = 0;
            return
        }
        this.setState({
            pins: this.state.pins.map((x, i) => i === index ? true : x)
        });
        this.currentPin++
        if(this.currentPin >= this.state.pins.length) {
            this.props?.onUnlocked?.();
        }
    }

    render() {
        return (
            <div className="Lock">
                {this.state.pins.map((x, i) => (
                    <KeyPin 
                        position={this.props.positions?.[i] || 50}
                        key={i}
                        onChange={value => this.pinChange(value, i)}
                        unlocked={x}
                    />
                ))}
            </div>
        );
    }
}

export default Lock;

